.site-index{
		
	.news{
        position: relative;
        z-index: 2;
		.newsbox{
			@include flex();
		}
		.leftside,
		.rightside{
			padding: 24px $side;
			@media screen and (max-width: $phone){
				padding: 8px 15px;	
			}
		}
		.leftside{
			width: 420px;
			background: $black;
			color: $white;
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				width: 250px;	
			}
			@media screen and (max-width: $phone){
				width: 100px;	
			}
			a{
				color: $white;
				display: inline-block;
				font-size: 14px;
				@media screen and (max-width: $tab){
					display: none;
				}
				i{
					display: inline-block;
					margin: 0 0 0 10px;
				}
			}
			h3{
				font-size: 13px;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 10px;
					width: 100%;
					text-align: center;
				}
				&:before{
					content: attr(data-eng);
					font-size: 22px;
					display: inline-block;
					margin: 0 20px 0 0;
					@media screen and (max-width: $phone){
						font-size: 16px;
						margin: 0;
						display: block;
					}
				}
			}
		}
		.rightside{
			width: calc(100% - 420px - 80px);
			background: $glay;
			@media screen and (max-width: $tab){
				width: calc(100% - 250px - 80px);
			}
			@media screen and (max-width: $phone){
				width: calc(100% - 100px);
			}
			.list{
				transition-timing-function: $ease;
				&__item{
					@include flex();
					align-items: center;
					opacity: 0;
					transition: opacity 400ms;
					&.swiper-slide-active{
						opacity: 1;
					}
					a{
						@include flex();
						align-items: center;
						justify-content: flex-start;
						color: $normal;
						line-height: 1.2;
						padding: 12px 0;
						&:hover{
							text-decoration: underline;
						}
						@media screen and (max-width: $phone){
							display: block;	
						}
					}
					time{
						width: 108px;
						border-right: rgba(#000,.16) 1px solid;
						@media screen and (max-width: $phone){
							border: none;
						}
					}
					h4{
						width: calc(100% - 108px);
						padding: 0 0 0 32px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						@media screen and (max-width: $phone){
							width: calc(100% - 15px);
							margin: 4px 0 0;
							padding: 0 15px 0 0;
						}
					}
				}
			}
		}
		.moreside{
			width: 80px;
			background: $glay;
			@include flex();
			align-items: center;
			justify-content: center;
			cursor: pointer;
			@media screen and (max-width: $phone){
				display: none;
			}
			&:hover{
				background: darken($glay,4%);
			}
			i{
				opacity: .24;
			}
		}
    }

	.weare{
		position: relative;
		overflow: hidden;
		&__box{
			width: 800px;
			margin: 0 auto;
			text-align: center;
			position: relative;
			@media screen and (max-width: $tab){
				width: auto;	
			}
			&__txt{
				font-size: 15px;
				font-weight: 700;
				display: block;
				margin: 0 0 6px;
				letter-spacing: .12em;
				text-indent: .1em;
				@media screen and (max-width: $phone){
					font-size: 12px;
					margin: 0 0 2px;
				}
			}
			&__title{
				font-size: 28px;
				line-height: 1.6;
				letter-spacing: .0em;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 16px;
					line-height: 1.7;
					margin: 0 30px;
				}
			}
			&__btn{
				text-align: center;
				margin: 24px 0 0;
				@media screen and (max-width: $phone){
					margin: 24px 15px 0;	
				}
				a{
					min-width: 280px;
					height: auto;
					padding: 13px 32px;
					font-size: 16px;
					letter-spacing: .16em;
					text-align: center;
					background: #FFF;
					border: $black 2px solid;
					color: $normal;
					display: inline-block;
					position: relative;
					transition: background 200ms $ease, color 200ms $ease, min-width 200ms $ease;
					@include lineAnime(40px, 20px);
					@media screen and (max-width: $phone){
						display: block;
						font-size: 15px;
					}
					&:hover{
						background: rgba($black,0);
						color: $black;
						min-width: 300px;
					}
				}
			}
		}
	}
	
	.business{
		padding: 420px 0 128px;
		position: relative;
		overflow: hidden;
		z-index: 2;
		@media screen and (max-width: $tab){
			padding-bottom: 64px;	
		}
		@media screen and (max-width: $phone){
			padding-top: 170px;
			padding-bottom: 40px;
		}
        h2{
            text-align: center;
            font-size: 140px;
            white-space: nowrap;
            font-weight: 600;
            letter-spacing: .032em;
            line-height: 1;
            position: absolute;
            top: 416px;
            left: 50%;
			color: darken($border, 4%);
			pointer-events: none;
			user-select: none;
			z-index: -1;
			transition: left 100ms linear;
			@media screen and (max-width: $tab){
				top: 300px;
				font-size: 112px;
			}
			@media screen and (max-width: $phone){
				top: 184px;
				font-size: 44px;
				transform: translateX(-30%);
				z-index: 3;
			}
        }
		&__padbg{
			content: '';
			width: 88%;
			height: 460px;
			position: absolute;
			top: 60px;
			left: 0;
			z-index: -1;
			overflow: hidden;
			@media screen and (max-width: $tab){
				height: 320px;	
			}
			@media screen and (max-width: $phone){
				width: 100%;
				height: 180px;
				top: 32px;
			}
			&__bg{
				position: absolute;
				top: -15%;
				left: 0;
				width: 100%;
				height: 130%;
				background: url("../../../images/index/left_photo.jpg") no-repeat;
				background-size: cover;
				@media screen and (max-width: $tab){
					background: url("../../../images/index/left_photo.jpg") no-repeat right;
					background-size: cover;
				}
				@media screen and (max-width: $phone){
					top: -25%;
					height: 150%;
				}
			}
		}
		&__box{
			position: relative;
			padding: 260px 7.2% 0;
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				padding: 30px 30px 0;	
			}
			&__message{
				width: 430px;
				padding: 64px 0;
				@media screen and (max-width: $tab){
					width: 320px;	
				}
				@media screen and (max-width: $phone){
					width: 100%;
					
					padding: 64px 15px 32px;
				}
				ul{
					li{
						font-size: 66px;
						line-height: 1.5;
						letter-spacing: .08em;
						font-weight: 700;
						white-space: nowrap;
						@media screen and (max-width: $tab){
							font-size: 44px;	
						}
						@media screen and (max-width: $phone){
							text-align: center;
							font-size: 26px;
						}
						span{
							display: inline-block;
							opacity: 0;
							transform: translateY(40px) skewY(10deg);
						}
					}
				}
				&__tt{
					font-size: 18px;
					font-weight: 700;
					margin: 24px 0 0;
					text-align: justify;
					opacity: 0;
					transform: translateY(40px);
					@media screen and (max-width: $tab){
						font-size: 16px;	
					}
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
				}
			}
			&__fig{
				width: calc(100% - 430px - 8vw);
				@include flex();
				justify-content: center;
				position: relative;
				padding: 64px 0;
				@media screen and (max-width: $tab){
					width: calc(100% - 320px - 8vw);
				}
				@media screen and (max-width: $phone){
					width: 100%;
					padding: 32px;
				}
				.line{
					position: absolute;
					top: 0;
					left: 0;
					background: $black;
					&--top{
						left: auto;
						right: -8vw;
						width: 0;
						height: 6px;
					}
					&--bottom{
						top: auto;
						bottom: 0;
						width: 0;
						height: 6px;
					}
					&--middle{
						width: 6px;
						height: 0;
					}
				}
				&__map{
					$size: 66vw;
					width: calc(#{$size} - 430px);
					min-width: calc(#{$size} - 430px);
					height: calc(#{$size} - 430px);
					margin: 0 0 0 8vw;
					position: relative;
					@media screen and (max-width: $tab){
						$size: 80vw;
						width: calc(#{$size} - 320px);
						min-width: calc(#{$size} - 320px);
						height: calc(#{$size} - 320px);
                        margin: 0 0 0 7vw;
					}
                    @media screen and (max-width: $phone){
						width: 72vw;
						min-width: 72vw;
						height: 72vw;
						min-height: 72vw;
						margin: 0 0 0 9vw;
                    }
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url("../../../images/index/arrow_map.png") no-repeat center;
						background-size: 100%;
						transform: scale(1.2);
						opacity: 0;
					}
					img{
						position: relative;
						display: block;
						width: 100%;
						opacity: 0;
						transform: scale(1.2);
						z-index: 2;
					}
				}
			}
			// Event
			.business__box__message{
                ._active{
                    li{
						span{
							transform: translateY(0) skewY(0);
							opacity: 1;
							@for $i from 1 through 10{
								&:nth-of-type(#{$i}){
									transition: transform 600ms 120ms * $i $ease,
									opacity 600ms 200ms * $i $ease;
								}
							}
						}
                    }
					+.business__box__message__tt{
						opacity: 1;
						transform: translateY(0);
						transition: transform 800ms 1500ms $ease,
						opacity 800ms 1200ms $ease;
					}
                }
			}
			.business__box__fig{
				&._active{
					.line{
						&.line--top,
						&.line--bottom{
							width: calc(100% + 8vw);
						}
						&.line--top{
							transition: width 400ms $ease;
						}
						&.line--middle{
							height: 100%;
							transition: height 400ms 380ms $ease;
						}
						&.line--bottom{
							transition: width 400ms 760ms $ease;
						}
					}
					.business__box__fig__map{
						$timer: 1200ms;
						animation: rote 20000ms $timer*2 linear infinite;
						@keyframes rote{
							0%{
								transform: rotate(0deg);
							}
							100%{
								transform: rotate(360deg);
							}
						}
						&:before{
							opacity: 1;
							transform: scale(1);
							transition: opacity 600ms $timer $ease, transform 600ms $timer $ease;
						}
						img{
							opacity: 1;
							transform: scale(1);
							transition: opacity 600ms $timer+600ms $ease, transform 600ms $timer+600ms $ease;
						}
					}
				}
			}
		}
	}
	
	.content{
		position: relative;
		overflow: hidden;
		z-index: 1;
		&.wrap{
			padding-top: 128px;
		}
		&:before{
			content: '';
			width: 0;
			height: 4px;
			background: $black;
			position: absolute;
			top: -144px;
			left: 7.2%;
		}
		h2{
			font-weight: 800;
			font-size: 18px;
			letter-spacing: .24em;
			top: 0;
			left: 7.2%;
			position: absolute;
			opacity: 0;
			@media screen and (min-width: $phone + 1px){
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				-webkit-writing-mode: vertical-rl;
				writing-mode: vertical-rl;
				white-space: nowrap;
				transform: translateY(40px) skewY(20deg);
			}
			@media screen and (max-width: $tab){
				left: 30px;	
			}
			@media screen and (max-width: $phone){
				position: relative;
				top: 0;
				left: 15px;
				margin: 0 0 20px;
			}
		}
		.servlist{
			padding: 0 7.2%;
			padding-left: 0;
			margin-left: calc(88px + 7.2%);
			transform: skewX(-6deg) translateX(100px);
			overflow: hidden;
			opacity: 0;
			@media screen and (max-width: $tab){
				padding: 0 30px 0 0;
				margin-left: 90px;
			}
			@media screen and (max-width: $phone){
				margin-left: 15px;	
			}
			.swiper-wrapper{
				transition-timing-function: $ease;
			}
			&__item{
				width: 420px;
				@media screen and (max-width: $tab){
					width: 320px;	
				}
				@media screen and (max-width: $phone){
					width: calc(100vw - 30px);
				}
				position: relative;
				&.swiper-slide-active,
				&.swiper-slide-next{
					a{
						filter: grayscale(0);
					}
				}
				a{
					color: $normal;
					display: block;
					position: relative;
					filter: grayscale(1);
					transition: filter 300ms $ease;
					&:hover{
						filter: grayscale(0);
						figure{
							transform: scale(.9);
							img{
								opacity: 1;
								transform: scale(1.2);
							}
						}
					}
				}
				figure{
					transition: transform 300ms $ease;
					overflow: hidden;
					img{
						width: 100%;
						transition: transform 300ms $ease;
					}
				}
				dl{
					margin: 16px 0 0;
					padding: 0 80px 0 0;
					position: relative;
					@media screen and (max-width: $tab){
						padding-right: 0;	
					}
					@media screen and (max-width: $phone){
						margin: 8px 0 0;	
					}
					&:before{
						content: "\f0a9";
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						position: absolute;
						top: 8px;
						right: 0;
						font-size: 17px;
						@media screen and (max-width: $phone){
							top: 4px;	
						}
					}
					dt{
						letter-spacing: .12em;
						font-size: 20px;
						font-weight: 800;
						margin: 0 0 12px 15px;
						position: relative;
						padding: 0 0 0 3.6em;
						@media screen and (max-width: $phone){
							font-size: 18px;
							padding-left: 3.2em;
						}
						strong{
							font-family:'DM Sans', sans-serif;
							font-size: 88px;
							font-weight: 700;
							line-height: 1;
							position: absolute;
							bottom: -5px;
							left: 0;
							@media screen and (max-width: $phone){
								font-size: 72px;
								display: inline-block;
								transform: translateY(-3px);	
							}
						}
					}
					dd{
						font-size: 13px;
						text-align: justify;
						margin: 0 0 0 20px;
					}
				}
			}
		}
		// Event
		&._active{
			&:before{
				width: 64px;
				transition: width 400ms $ease;
			}
			h2{
				opacity: 1;
				transform: translateY(0) skewY(0deg);
				transition: opacity 800ms 200ms $ease, transform 800ms 200ms $ease;
			}
			.servlist{
				opacity: 1;;
				transform: translateX(0) skewX(0deg);
				transition: opacity 800ms 800ms $ease, transform 800ms 800ms $ease;
			}
		}
	}

	.blog{
		background: $glay;
		position: relative;
		&.wrap{
			padding-top: 140px;
			@media screen and (max-width: $tab){
				padding-top: 104px;
			}
			@media screen and (max-width: $phone){
				padding-top: 80px;	
			}
		}
		&:after{
			content: '';
			width: 100%;
			height: 32px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			background-image: url("../../../images/index/kussion_wave.png");
			background-repeat: repeat-x;
			background-position: 0 0;
			animation: wave 3000ms cubic-bezier(0.37, 0, 0.63, 1) infinite;
			@keyframes wave{
				0%{
					background-position: 0 0;
				}
				100%{
					background-position: 2560px 0;
				}
			}
		}

		&__title{
			font-size: 32px;
			font-weight: 700;
			letter-spacing: .12em;
			line-height: 1;
			margin: 0 0 36px;
			@media screen and (max-width: $phone){
				font-size: 22px;
				margin: 0 0 20px;
			}
			&:after{
				content: attr(data-eng);
				font-size: 12px;
				display: inline-block;
				margin: 0 0 0 16px;
			}
		}
		&__list{
			margin: 0 0 0 -40px;
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $tab){
				margin: 0 0 0 -30px;	
			}
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			&__item{
				width: 33.3%;
				padding: 0 0 0 40px;
				@include flex();
				@media screen and (max-width: $tab){
					padding: 0 0 0 30px;	
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					display: block;
					margin: 0 0 15px;
					&:last-child{
						margin: 0;
					}
				}
				a{
					width: 100%;
					display: block;
					color: $normal;
					height: auto;
					background: #FFF;
					position: relative;
					box-shadow: 0 0 24px rgba(#000,.08);
					@media screen and (max-width: $phone){
						@include flex();
						padding: 15px;
					}
					&:hover{
						@media screen and (min-width: $phone + 1px){
							figure{
								img{
									opacity: 1;
									transform: scale(1.2);
									transition: transform 2000ms linear;
								}
								&:before{
									opacity: .32;
								}
								&:after{
									top: 50%;
									opacity: 1;
								}
							}
						}
					}
				}
				figure{
					position: relative;
					overflow: hidden;
					@media screen and (max-width: $phone){
						width: 100px;	
					}
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: $black;
						opacity: 0;
						pointer-events: none;
						z-index: 2;
						transition: opacity 400ms $ease;
					}
					&:after{
						content: 'READ MORE';
						position: absolute;
						opacity: 0;
						top: calc(50% + 20px);
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
						background: #FFF;
						border-radius: 999px;
						padding: 4px 32px;
						box-shadow: 0 0 32px rgba(#000,.12);
						z-index: 2;
						transition: top 400ms $ease, opacity 400ms $ease;
					}
					img{
						width: 100%;
						transition: transform 200ms linear;
					}
				}
				.heart{
					position: absolute;
					top: 8px;
					right: 10px;
					background: #FFF;
					border-radius: 999px;
					padding: 4px 16px;
					font-size: 13px;
					z-index: 2;
					@media screen and (max-width: $phone){
						padding: 0;
						background: none;
						right: 15px;
					}
					i{
						color: $red;
						display: inline-block;
						margin: 0 6px 0 0;
					}
				}
				&__box{
					padding: 20px;
					font-size: 12px;
					@include flex();
					@media screen and (max-width: $tab){
						display: block;	
					}
					@media screen and (max-width: $phone){
						width: calc(100% - 100px);
						padding: 0;
						padding-left: 15px;
						@include flex();
						align-items: center;
					}
					h3{
						font-weight: 700;
						font-size: 15px;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin: 0 0 10px;
						@media screen and (max-width: $phone){
							order: 2;
							margin: 0;
							font-size: 14px;
						}
					}
					time{
						width: 100px;
						padding: 1px 0;
						border: $black 1px solid;
						color: $normal;
						text-align: center;
						@media screen and (max-width: $tab){
							display: block;	
						}
						@media screen and (max-width: $phone){
							order: 1;
							font-size: 10px;
						}
					}
					ul{
						width: calc(100% - 100px);
						padding: 0 0 0 12px;
						@include flex();
						align-items: center;
						justify-content: flex-start;
						@media screen and (max-width: $tab){
							width: auto;
							padding: 12px 0 0;
						}
						@media screen and (max-width: $phone){
							display: none;
						}
						li{
                            display: block;
                            padding: 1px 12px;
                            background: $glay;
                            border: $glay 1px solid;
							margin: 0 12px 0 0;
							&:last-child{
								margin: 0;
							}
						}
					}
				}
                p{
                    text-align: justify;
                    font-size: 13.5px;
                    padding: 15px 20px;
                    border-top: $glay 4px solid;
					@media screen and (max-width: $phone){
						display: none;
					}
                }
			}
		}
	}
	.more{
		position: absolute;
		top: -8px;
		right: 40px;
        min-width: 280px;
        height: auto;
		background: #FFF;
        padding: 11px 32px;
        font-size: 14px;
        letter-spacing: .16em;
        border: $black 2px solid;
        color: $normal;
        display: inline-block;
		@include lineAnime(40px, 20px, $black);
        transition: background 200ms $ease, color 200ms $ease, min-width 200ms $ease;
		@media screen and (max-width: $tab){
			right: 30px;
		}
		@media screen and (max-width: $phone){
			position: relative;
			right: auto;
			top: 0;
			width: 100%;
			margin: 24px 0 0;
			font-size: 15px;
			padding: 13px 32px;
			text-align: center;
		}
		i{
			display: inline-block;
			margin: 0 8px 0 0;
		}
        &:before{
            transition: background 200ms $ease;
        }
        &:hover{
			background: $normal;
			color: #FFF;
            &:before,
			&:after{
                background: #FFF;
            }
        }
	}

	.bnrarea{
		margin: 60px 0 0;
		padding: 60px 0 0;
		border-top: rgba(#000,.08) 1px solid;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;
			padding: 24px 0 0;
		}
		&__list{
			@include flex();
			&__item{
				width: calc(50% - 15px);
				margin: 30px 0 0;
				@media screen and (max-width: $phone){
					width: calc(50% - 8px);
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				a{
					@include flex();
					align-items: center;
					justify-content: center;
					background: #FFF;
					padding: 40px 0;
					color: $normal;
					position: relative;
					overflow: hidden;
					box-shadow: 0 0 24px rgba(#000,.08);
					transition: box-shadow 200ms $ease;
					@media screen and (max-width: $phone){
						padding: 15px;
					}
					&:hover{
						box-shadow: none;
						&:before{
							opacity: 1;
							top: 8px;
							left: 8px;
							width: calc(100% - 16px);
							height: calc(100% - 16px);
						}
						i{
							transform: scale(1.1);
						}
					}
					&:before{
						content: '';
						opacity: 0;
						width: 100%;
						height: 100%;
						border: $black 2px solid;
						position: absolute;
						top: 0;
						left: 0;
						@include transition(200ms);
					}
					&:after{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 12px 12px;
						border-color: transparent transparent $black transparent;
						position: absolute;
						right: 8px;
						bottom: 8px;
					}
				}
				&__data{
					text-align: center;
					line-height: 1;
					figure{
						width: 80px;
						margin: 0 auto;
						@media screen and (max-width: $phone){
							width: 40px;	
						}
						img{
							width: 100%;
						}
					}
					&__ttl{
						text-align: center;
						font-size: 12px;
						letter-spacing: .12em;
						margin: 24px 0 0;
						&:before{
							content: attr(data-eng);
							font-size: 22px;
							font-weight: 800;
							letter-spacing: .24em;
							display: block;
							margin: 0 0 12px;
							@media screen and (max-width: $phone){
								font-size: 14px;
								margin: 0 0 8px;
							}
						}
					}
				}
			}
		}
	}

    .kussion{
		position: relative;
		height: 100vh;
		min-height: 640px;
		overflow: hidden;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		@media screen and (max-width: $tab){
			height: 480px;
			min-height: 1px;
		}
		@media screen and (max-width: $phone){
			background: $black;
			height: 320px;
		}
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			background: $black;
			position: absolute;
			top: 0;
			left: 0;
			opacity: .4;
			z-index: 2;
		}
		&__bg{
			position: absolute;
			top: -15%;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 130%;
			background-position: center;
			background-size: cover;
			@media screen and (max-width: $tab){
				top: -20%;
				height: 140%;
			}
		}
		&__data{
			color: #FFF;
			position: relative;
			z-index: 2;
			margin: 0 0 0 8%;
			transform: translateY(-15px);
			dt{
				font-size: 56px;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 24px 4px;
				position: relative;
				&:first-letter{
					font-size: 2em;
					display: inline-block;
					margin: 0 8px 0 0;
				}
				&:after{
					@include min();
					content: attr(data-text);
					font-size: 16px;
					letter-spacing: .24em;
					position: absolute;
					top: 26px;
					left: 84px;
				}
			}
			dd{
				width: 360px;
				text-align: justify;
				font-size: 14px;
				&.btn{
					margin: 32px 0 0;
					width: auto;
					a{
						display: inline-block;
						color: #FFF;
						font-size: 18px;
						position: relative;
						padding: 0 64px 0 0;
						&:before{
							content: '';
							width: 40px;
							height: 1px;
							position: absolute;
							top: 50%;
							right: 0;
							background: #FFF;
						}
						&:after{
							content: '';
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 4px 0 4px 8px;
							border-color: transparent transparent transparent #fff;
							position: absolute;
							top: 50%;
							right: -1px;
							transform: translateY(-50%);
						}
					}
				}
			}
		}
		&__center{
			text-align: center;
			color: #FFF;
			z-index: 2;
			@include flex();
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			a{
				color: #FFF;
			}
			dl{
				dt{
					font-size: 16px;
					font-weight: 700;
					letter-spacing: .08em;
					margin: 0 0 8px;
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
				}
				dd{
					font-size: 15px;
					@media screen and (max-width: $phone){
						margin: 0 30px;	
					}
					&.ttl{
						font-size: 28px;
						font-weight: 700;
						line-height: 1;
						@media screen and (max-width: $phone){
							font-size: 24px;	
						}
					}
					&.btn{
						margin: 40px 0 0;
						@media screen and (max-width: $phone){
							margin: 24px 15px 0;	
						}
						a{
							border: #FFF 1px solid;
							padding: 11px 56px;
							font-size: 17px;
							@include transition(200ms);
							@media screen and (max-width: $phone){
								display: block;
								padding: 12px 0;
							}
							&:hover{
								background: #FFF;
								color: $normal;
							}
						}
					}
				}
			}
		}
    }
}