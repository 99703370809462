.site-faq{
	.faqsec{
		margin: 0 0 72px;
		@media screen and (max-width: $phone){
			margin: 0 0 40px;	
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		&__list{
			li{
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					margin: 0 0 15px;	
				}
				&:last-child{
					margin: 0;
				}
				dl{
					background: $glay;
					border-radius: 4px;
					padding: 20px;
					transition: transform 200ms $ease;
					cursor: pointer;
					@media screen and (max-width: $phone){
						padding: 15px;	
					}
					&.active{
						transform: scale(1.02);
						box-shadow: 0 16px 32px rgba($black,.12); 
						background: #FFF;
						dd{
							border-top-color: $border;
						}
					}
					dt,dd{
						position: relative;
						padding-left: 32px;
						&:before{
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							z-index: 2;
							font-size: 20px;
							line-height: 1;
							font-weight: 700;
						}
					}
					dt{
						font-size: 18px;
						font-weight: 700;
						padding-right: 32px;
						@media screen and (max-width: $phone){
							font-size: 15px;	
						}
						&:before{
							content: 'Q';
							top: 4px;
							color: #17a2b8;
							@media screen and (max-width: $phone){
								top: 2px;	
							}
						}
						&:after{
							content: "\f078";
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							font-size: 13px;
							opacity: .24;
							transition: transform 200ms $ease;
						}
						&.active{
							&:after{
								transform: translateY(-50%) rotate(-180deg);
							}
						}
					}
					dd{
						border-top: #FFF 1px solid;
						margin-top: 12px;
						padding-top: 12px;
						@media screen and (max-width: $phone){
							font-size: 14px;
							text-align: justify;
						}
						&:before{
							content: 'A';
							top: 14px;
							font-size: 22px;
							left: 2px;
							color: #ffc107;
						}
					}
				}
			}
		}
	}
}