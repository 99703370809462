.site-weare{
	.first{
		position: relative;
		overflow: hidden;
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("../../../images/weare/concept_back.jpg") no-repeat center center;
			background-size: cover;
			z-index: -1;
			opacity: .12;
		}
	}
	.catch{
		font-size: 52px;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		@media screen and (max-width: $phone){
			font-size: 28px;	
		}
		.del{
			display: block;
			margin: 0 0 6px;
			small{
				font-size: 32px;
				position: relative;
				display: inline-block;
				color: darken($border, 16%);
				@media screen and (max-width: $phone){
					font-size: 20px;	
				}
				margin: 0;
				&:before{
					content: '';
					width: 110%;
					height: 4px;
					background: $normal;
					position: absolute;
					transform: rotate(6deg);
					top: 56%;
					left: -5%;
					@media screen and (max-width: $phone){
						height: 2px;	
					}
				}
			}
		}
	}
	.minibox{
		margin-top: 48px;
		text-align: center;
		@media screen and (max-width: $phone){
			margin-top: 20px;
		}
		h3{
			font-size: 28px;
			line-height: 1.6;
			font-weight: 700;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 16px;
				margin: 0 15px 16px;
			}
		}
		p.mb{
			font-size: 16px;
			line-height: 1.88;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 14px;
				margin: 0 15px 15px;
				text-align: justify;
			}
			&:last-of-type{
				margin: 0;
				@media screen and (max-width: $phone){
					margin: 0 15px;	
				}
			}
		}
	}
	
	.concept{
		position: relative;
		overflow: hidden;
		&__catch{
			margin: 0 0 0 80px;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			position: relative;
			z-index: 2;
			@media screen and (max-width: $phone){
				font-size: 15px;
				margin: 0 0 0 15px;
			}
            &:after{
                content: attr(data-eng);
                display: block;
                font-size: 64px;
                margin: 16px 0 0;
				text-indent: -.04em;
				@media screen and (max-width: $phone){
					font-size: 32px;
					margin: 8px 0 0;
				}
            }
		}
		&__over{
			position: relative;
			padding: 96px 0;
			margin-top: 48px;
			@media screen and (max-width: $phone){
				padding: 32px 15px;
				margin-top: 32px;
			}
			&:before{
				content: '';
				background: #FFF;
				width: 80%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				@media screen and (max-width: $phone){
					width: 100%;	
				}
			}
			&__bg{
				background-size: cover;
				background-position: center;
				position: absolute;
				top: -96px;
				right: 0;
				width: 50%;
				height: 100%;
				@media screen and (max-width: $phone){
					position: relative;
					top: 0;
					width: 100%;
					height: 50vw;
					transform: translateX(0) !important;
				}
			}
		}
		&__box{
			position: relative;
			width: 50%;
			padding: 0 80px;
			z-index: 2;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0 15px;
				margin: 32px 0 0;
			}
			&__data{
				max-width: 520px;
				margin: 0 auto;
				@media screen and (max-width: $phone){
					max-width: 100%;	
				}
				dt{
					font-size: 28px;
					font-weight: 700;
					margin: 0 0 32px;
					@media screen and (max-width: $phone){
						font-size: 18px;
						margin: 0 0 16px;
					}
				}
				dd{
					font-size: 16px;
					line-height: 2;
					margin: 16px 0 0;
					text-align: justify;
					@media screen and (max-width: $phone){
						font-size: 14px;
						margin: 8px 0 0;
					}
				}
			}
		}
	}
	
	.kaizoku{
		position: relative;
		&__bg{
			position: absolute;
			top: 104px;
			left: 0;
			width: 50%;
			height: calc(100% - (104px * 2));
			background-size: cover;
			background-position: center;
			@media screen and (max-width: $phone){
				position: relative;
				top: -8px;
				left: 15px;
				width: calc(100% - 30px);
				height: 50vw;
				transform: translateY(0) !important;
			}
		}
		&__txt{
			width: 50%;
			padding: 80px;
			margin: 0 0 0 50%;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0 15px;
				margin: 0;
			}
			dl{
				max-width: 520px;
				margin: 0 auto;
				@media screen and (max-width: $phone){
					max-width: 100%;
					margin: 15px 0 0;
				}
				dt{
					font-size: 32px;
					letter-spacing: .024em;
					line-height: 1.5;
					font-weight: 800;
					margin: 0 0 32px;
					@media screen and (max-width: $phone){
						font-size: 16px;
						margin: 0 15px 15px;
					}
					strong{
						font-size: 56px;
						font-weight: 700;
						@media screen and (max-width: $phone){
							font-size: 28px;	
						}
					}
				}
				dd{
					font-size: 16px;
					line-height: 2;
					margin: 16px 0 0;
					text-align: justify;
					@media screen and (max-width: $phone){
						font-size: 14px;
						margin: 12px 15px 0;
					}
				}
			}
		}
	}
	
	.staff{
		background: $glay;
		h2.crew{
			text-align: center;
			font-size: 40px;
			font-weight: 700;
			margin: 0 0 16px;
			@media screen and (max-width: $phone){
				font-size: 24px;
				margin: 0 0 12px;
			}
		}
		.context{
			font-size: 17px;
			text-align: center;
			margin: 0 0 24px;
			transform: translateY(-8px);
			@media screen and (max-width: $phone){
				font-size: 15px;	
			}
		}
		&__list{
			@include flex();
			margin: 0 0 0 -40px;
			@media screen and (max-width: $phone){
				margin: 0 0 0 -15px;
				align-items: flex-start;
			}
			li{
				@media screen and (min-width: $phone + 1px){
					width: 25%;
					margin: 40px 0 0;
					padding: 0 0 0 40px;
					&:nth-child(-n+4){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: 50%;
					margin: 15px 0 0;
					padding: 0 0 0 15px;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				figure{
					width: 230px;
					height: 230px;
					margin: 0 auto;
					border-radius: 50%;
					overflow: hidden;
					@media screen and (max-width: $phone){
						width: 130px;
						height: 130px;
					}
					img{
						width: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				dl{
					text-align: center;
					margin: 12px 0 0;
					dd{
						font-size: 13px;
						@media screen and (max-width: $phone){
							font-size: 11px;	
						}
					}
					dt{
						font-weight: 700;
						font-size: 16px;
						@media screen and (max-width: $phone){
							font-size: 14px;	
						}
					}
				}
			}
		}
	}
}