#site-header{
	position: fixed;
	top: 0;
	left: 0;
	padding: 30px $side;
	width: 100%;
	@include flex();
	color: #FFF;
	align-items: center;
	z-index: 9999;
	transition: padding 400ms;
	@media screen and (max-width: $tab){
		padding: 20px 30px;	
	}
	@media screen and (max-width: $phone){
		padding: 15px;	
	}
	&.active{
		padding: 20px $side;
		mix-blend-mode: exclusion;
		@media screen and (max-width: $tab){
			padding: 20px 30px;	
		}
		@media screen and (max-width: $phone){
			padding: 15px;	
		}
	}
	&.mix{
		mix-blend-mode: normal;
		color: $normal;
		.logo{
			background: url("../../../images/common/logo_2x_b.png") no-repeat center;
			background-size: 140px;
			@media screen and (max-width: $phone){
				background-size: 120px;
			}
			img{
				opacity: 0;
			}
		}
		.localnav{
			ul{
				li{
					a{
						color: $normal;
					}
				}
			}
		}
		.openmenu{
			.openmenu__inner{
				&:before{
					border-color: $normal;
					transition: border 400ms;
				}
				.openmenu__inner__icon{
					figure{
						background: url("../../../images/common/menu_skull_b.png") no-repeat center bottom;
						background-size: 30px;
						transform: rotate(-360deg);
						@media screen and (max-width: $phone){
                              background-size: 26px;
						}
						img{
							opacity: 0;
						}
					}
				}
			}
		}
	}
	*{
		transition: color 400ms;
	}
	img{
		transition: opacity 400ms;
	}
	.logo{
		width: 140px;
		@media screen and (max-width: $phone){
			width: 120px;	
		}
		img{
			width: 100%;
		}
	}
	.gnavi{
		display: none;
	}
	.localnav{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		@media screen and (max-width: $tab){
			display: none;
		}
		ul{
			@include flex();
			align-items: center;
			li{
				font-size: 15px;
				margin: 0 4.4vw 0 0;
				&:last-child{
					margin: 0;
				}
				a{
					color: #FFF;
					position: relative;
					display: block;
					letter-spacing: .08em;
					font-weight: 700;
					&:before{
						content: '';
						width: 0;
						height: 2px;
						background: #FFF;
						position: absolute;
						left: 0%;
						bottom: -4px;
						transition: width 200ms $ease;
					}
					&:hover{
						&:before{
							width: 100%;
						}
					}
				}
			}
		}
	}
	.openmenu{
		@include flex();
		align-items: center;
		cursor: pointer;
		&:hover{
			.openmenu__txt{
				margin: 0 24px 0 0;
			}
			.openmenu__inner{
				&:before{
					width: 130%;
					height: 130%;
					opacity: 1;
					border-width: 3px;
					top: -15%;
					left: -15%;
				}
				figure{
					transform: rotate(180deg) scale(1.1);
				}
			}
		}
		&__txt{
			margin: 0 28px 0 0;
			font-size: 14px;
			letter-spacing: .08em;
			font-weight: 700;
			transition: margin 200ms $ease;
			@media screen and (max-width: $phone){
				margin: 0 16px 0 0;	
			}
		}
		&__inner{
			$size: 38px;
			width: $size;
			height: $size;
			border-radius: 50%;
			color: $white;
			@include flex();
			align-items: center;
			justify-content: center;
			text-align: center;
			position: relative;
			&:before{
				content: '';
				width: 130%;
				height: 130%;
				border: #FFF 2px solid;
				position: absolute;
				top: -15%;
				left: -15%;
				border-radius: 50%;
				@include transition(400ms);
				@media screen and (max-width: $phone){
					width: 110%;
					height: 110%;
					top: -5%;
					left: -5%;
				}
			}
			&__icon{
				figure{
					width: 30px;
					height: 30px;
					position: relative;
					transition: transform 400ms $ease;
					@media screen and (max-width: $phone){
						width: 26px;
						height: 26px;
					}
					img{
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
}

.menunav{
	$timer: 600ms; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 9999;
	transition: left $timer cubic-bezier(0.83, 0, 0.17, 1);
	visibility: hidden;
	&.active{
		visibility: visible;
		.menunav__links{
			li{
				opacity: 0;
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						animation: li 600ms $ease $timer+$i*80ms forwards;
					}
				}
			}
			@keyframes li{
				0%{
					transform: translateY(20px) skewX(15deg);
					opacity: 0;
				}
				100%{
					transform: translateY(0) skewX(0deg);
					opacity: 1;
				}
			}
		}
		.menunav__sns{
			animation: fadeins 800ms $timer*2 forwards;
			@keyframes fadeins{
				0%{
					opacity: 0;
				}
				100%{
					opacity: 1;
				}
			}
		}
	}
	.close{
        $size: 38px;
        width: $size;
        height: $size;
        border-radius: 50%;
        color: $normal;
        @include flex();
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
		position: absolute;
		top: 30px;
		right: 50px;
		cursor: pointer;
		z-index: 1000;
		@media screen and (max-width: $tab){
			top: 20px;
			right: 30px;
		}
		@media screen and (max-width: $phone){
			top: 15px;
			right: 15px;
		}
        &:before{
            content: '';
            width: 130%;
            height: 130%;
            border: $border 2px solid;
            position: absolute;
            top: -15%;
            left: -15%;
            border-radius: 50%;
            @include transition(200ms);
			@media screen and (max-width: $phone){
				width: 110%;
				height: 110%;
				top: -5%;
				left: -5%;
			}
        }
		i{
			@include transition(200ms);
		}
		&:hover{
			i{
				transform: scale(1.2);
			}
            &:before{
                width: 130%;
                height: 130%;
                opacity: 1;
                border-width: 4px;
                top: -15%;
                left: -15%;
            }
		}
	}
	&__sns{
		color: $normal;
		position: absolute;
		bottom: 30px;
		right: 50px;
		@include flex();
		opacity: 0;
		@media screen and (max-width: $phone){
			position: absolute;
			right: auto;
			left: 0;
			width: 100%;
			align-items: center;
			justify-content: center;
		}
		a{
			color: $normal;
			font-size: 15px;
			@media screen and (max-width: $phone){
				font-size: 18px;
			}
			&:hover{
				opacity: .64;
			}
		}
		li{
			margin: 0 24px 0 0;
			&:last-child{
				margin: 0;
			}
		}
	}
	&__cover{
		width: calc(50% - 25px);
		height: 100%;
		background: url("../../../images/common/menu_cover.jpg") no-repeat center;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		@media screen and (max-width: $tab){
			display: none;
		}
		&:before{
			content: '';
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background: url("../../../images/common/menu_wave.png") repeat-y;
			background-position: 0 0;
			background-size: 50px;
			transform: rotateY(180deg);
			animation: wav 8000ms infinite linear;
		}
	}
	&__links{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%);
		color: $normal;
		padding: 0 0 0 80px;
		@media screen and (max-width: $tab){
			position: relative;
			top: 0;
			left: 0;
			transform: translateY(0);
			padding: 120px;
			@media screen and (max-width: $phone){
				padding: 80px 30px 120px;
				height: 100%;
				overflow: auto;
			}
		}
		ul{
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				//display: block;	
			}
		}
		li{
			width: 50%;
			max-width: 280px;
			margin: 0 0 24px;
			@media screen and (max-width: $tab){
				max-width: 100%;	
			}
			@media screen and (max-width: $phone){
				width: 50%;
				margin: 0 0 24px;
			}
            a:not(.btn){
				display: inline-block;
                color: $normal;
                font-size: 14px;
				position: relative;
				&:after{
					content: '';
					width: 0;
					height: 3px;
					background: $normal;
					border-radius: 3px;
					position: absolute;
					bottom: -4px;
					left: 0;
					transform: translateY(-50%);
					transition: width 200ms $ease;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
				@media screen and (max-width: $phone){
					font-size: 11px;
					display: block;
				}
                &:before{
                    content: attr(data-eng);
                    font-size: 24px;
                    font-weight: 700;
                    display: inline-block;
                    margin: 0 12px 0 0;
					@media screen and (max-width: $phone){
						font-size: 18px;
						display: block;
						margin: 0 0 2px;
					}
                }
				&:hover{
					&:after{
						width: 100%;	
					}
				}
            }
			&.contact{
				width: 100%;
				max-width: 100%;
				margin: 24px 0 0;
				font-weight: 700;
				@media screen and (max-width: $phone){
					margin: 0;	
				}
				a.btn{
					color: $normal;
					border: $normal 2px solid;
					display: block;
					width: 240px;
					text-align: center;
					padding: 10px 0;
					font-size: 16px;
					position: relative;
					@media screen and (max-width: $phone){
						width: 100%;
						padding: 9px 0;
					}
					&:before{
						display: none;
					}
					&:after{
						content: '';
						width: 0;
						height: 100%;
						background: $normal;
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
						transition: width 240ms $ease;
					}
					i{
						display: inline-block;
						margin: 0 8px 0 0;
					}
					&:hover{
						color: #FFF;
						&:after{
							width: 100%;
						}
					}
				}
			}
		}
	}
    @keyframes wav{
        0%{
            background-position: 0 0;
        }
        100%{
            background-position: 0 800px;
        }
    }
}
