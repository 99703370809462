@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700;900&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#202020;
$link:#D02;
$border:#e8e8e9;
$black: #040406;
$glay: #f4f4f5;
$white: #e5e5e5;
$red: #d90c0c;

/* SITE SETTING
------------------------------------------------------------*/
$pc-head:80px;
$sp-head:60px;
$side: 50px;
$ease: cubic-bezier(0.65, 0, 0.35, 1);

$width: 1120px;
$tab: $width - 1px;
$phone: 640px;



@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        30px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        15px, //パディング
		#FFF //ボディ背景
	);
}

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@mixin lineAnime($size:32px, $right:0, $color:$black){
    &:before,
    &:after{
        transition: background 200ms $ease;
		border-radius: 999px;
    }
    &:before{
        content: '';
        position: absolute;
        right: $right;
        top: 50%;
        background: $color;
        width: $size;
        height: 1.5px;
		animation: cb 1200ms $ease infinite;
        @keyframes cb{
            0%{
                width: 0;
                right: $right + $size;
            }
            33%{
                width: $size;
                right: $right;
            }
            66%{
                width: $size;
                right: $right;
            }
            100%{
                width: 0;
                right: $right;
            }
		}
    }
    &:after{
        content: '';
        width: 16px;
        height: 1.5px;
        position: absolute;
        background: $color;
        top: 50%;
        right: $right;
        transform: rotate(24deg);
        transform-origin: right center;
		animation: arrowmove 1200ms $ease infinite;
		@keyframes arrowmove{
			0%{
				width: 0;
				transform: rotate(45deg);
			}
			33%{
				width: 16px;
				transform: rotate(24deg);
			}
			66%{
				width: 16px;
				transform: rotate(24deg);
			}
			100%{
				width: 0;
				transform: rotate(45deg);
			}
		}
    }
    &:hover{
        background: rgba($black,0);
        color: $black;
        &:before,
        &:after{
            background: $black;
        }
    }
}


@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/firstview";
@import "page/index";
@import "page/company";
@import "page/service";
@import "page/recruit";
@import "page/weare";
@import "page/faq";
@import "page/blog";
@import "page/edit";

#site-wrapper,#site-footer{
}

.site-module{
	&.wrap{
		padding: 104px 0;
		@media screen and (max-width: $tab){
			padding: 80px 0;	
		}
		@media screen and (max-width: $phone){
			padding: 40px 0;	
		}
		&.mid{
			padding-top: 80px;
			padding-bottom: 80px;
			@media screen and (max-width: $tab){
				padding-top: 64px;
				padding-bottom: 64px;
			}
			@media screen and (max-width: $phone){
				padding-top: 40px;	
				padding-bottom: 40px;	
			}
		}
		&.lar{
			padding-top: 144px;
			padding-bottom: 144px;
			@media screen and (max-width: $tab){
				padding-top: 104px;
				padding-bottom: 104px;
			}
			@media screen and (max-width: $phone){
				padding-top: 40px;	
				padding-bottom: 40px;
			}
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
    &.glay{
        background: $glay;
    }
    &.white{
        background-color: #FFF;
    }

	h2.large{
		text-align: center;
		margin: 0 0 40px;
		line-height: 1;
		font-size: 20px;
		font-weight: 700;
		@media screen and (max-width: $phone){
			font-size: 15px;
			margin: 0 0 20px;
		}
		&:before{
			font-family:'DM Sans', sans-serif;
			content: attr(data-eng);
			font-size: 48px;
			letter-spacing: .04em;
			display: block;
			margin: 0 0 10px;
			@media screen and (max-width: $phone){
				font-size: 32px;
				font-weight: 800;
				margin: 0 0 8px;
			}
		}
	}
    h2,h3,h4{
        &.borders{
            border-bottom: rgba(#000,.1) 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 24px;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 17px;
				padding-left: 16px;
				margin: 0 0 16px;
			}
            &:after{
                content: '';
                width: 5px;
                height: 60%;
                background: $black;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 20%;
                left: 0;
            }
        }
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 12px;
		}
    }

	.tablestyle{
		border: $border 1px solid;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				@media screen and (max-width: $phone){
					display: block;
					border-bottom: $border 1px solid;
					&:last-child{
						border: none;
					}
				}
				th,td{
					padding: 24px;
					text-align: left;
					vertical-align: middle;
					border: $border 1px solid;
					font-size: 15px;
					@media screen and (max-width: $phone){
						display: block;
						padding: 15px;
						border: none;
						font-size: 13px;
					}
				}
				th{
					width: 240px;
					font-weight: 800;
					@media screen and (max-width: $phone){
						width: auto;
						background: $glay;
						border-bottom: $border 1px solid;
					}
				}
				td{
					dl{
						margin: 0 0 20px;
						&:last-of-type{
							margin: 0;
						}
						dt{
							background: $glay;
							font-weight: 700;
							width: 200px;
							padding: 4px 0;
							margin: 0 0 8px;
							text-align: center;
						}
					}
				}
			}
		}
	}
	.btnarea{
		margin: 32px 0 0;
        a{
			display: inline-block;
			font-size: 16px;
			padding: 12px 56px;
			background: $black;
			border: $black 2px solid;
			color: #FFF;
			position: relative;
			@include transition(200ms);
			&:after{
				content: "\f054";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
				font-size: 11px;
			}
			&:hover{
				background: #FFF;
				color: $normal;
			}
        }
	}
	.formstyle{
		border: none;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					padding: 15px 0;
				}
				th,
				td{
					padding: 32px 0;
					vertical-align: middle;
					@media screen and (max-width: $phone){
						display: block;
						padding: 0;
					}
				}
				th{
					width: 260px;
					text-align: left;
					font-size: 16px;
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 8px;
						font-size: 14px;
					}
					&.hiss{
						&:after{
							content: '必須項目';
							background: $black;
							color: #FFF;
							float: right;
							padding: 4px 12px;
							border-radius: 3px;
							font-size: 13px;
							@media screen and (max-width: $phone){
								padding: 2px 12px;
								font-size: 12px;
							}
						}
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width: $phone){
						padding: 0;	
					}
					input[type=text],
					input[type=tel],
					input[type=email],
					input[type=password],
					select,
					textarea{
						position: relative;
						z-index: 1;
						@include font();
						font-size: 15px;
						padding: 10px;
						background: $glay;
						border-radius: 4px;
						display: block;
						width: 100%;
						outline: none;
						border: none;
						@include transition(160ms);
						&:focus{
							border-color: #FFF;
							background: #FFF;
							z-index: 2;
							box-shadow: 0 4px 24px rgba(#000,.12);
							transform: scale(1.02);
						}
					}
					textarea{
						min-width: 100%;
						max-width: 100%;
						min-height: 200px;
					}
				}
			}
		}
	}
	.send{
		text-align: center;
		margin: 40px 0 0;
		input{
			@include font();
			width: 320px;
			height: 56px;
			background: $black;
			border: $black 2px solid;
			color: #FFF;
			cursor: pointer;
			font-size: 18px;
			font-weight: 700;
			&:hover{
				background: #FFF;
				color: $black;
			}
		}
	}
	.pager{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 32px 0 0;
		a,
		span{
			width: 36px;
			height: 36px;
			background: $glay;
			color: $normal;
			@include flex();
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin: 0 6px;
			@include transition(200ms,$ease:$ease);
		}
		a{
			&:hover{
				background: #FFF;
				box-shadow: inset 0 8px 32px rgba($black,.24);
			}
		}
		span{
			color: $white;
			background: $black;
		}
	}
	.underbtn{
		margin: 40px 0 0;
		text-align: center;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		a{
			display: inline-block;
			border: $black 2px solid;
			padding: 14px 96px;
			text-align: center;
			background: #FFF;
			color: $normal;
			font-size: 16px;
			position: relative;
			transition: background 200ms, color 200ms;
			@include lineAnime(40px,20px, $black);
			@media screen and (max-width: $phone){
				font-size: 15px;
				display: block;
				padding: 14px 0;
			}
			&:hover{
				background: $black;
				color: #FFF;
				&:before,
				&:after{
					background: #FFF;
				}
			}
		}
	}
}

.site-404{
	.alertmes{
		text-align: center;
	}
}


.scale-enter-active, .scale-leave-active {
	transition: 800ms $ease;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.04);
}

.loading{
	visibility: hidden;
	&.visible{
		visibility: visible;
	}
	&.active{
		.left,
		.right{
			&:before,
			&:after{
				height: 0;
				transition: height 800ms $ease;
			}
		}
		.svg{
			opacity: 0;
			transition: opacity 400ms $ease;
		}
	}
	.svg{
		width: 300px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
		@media screen and (max-width: $phone){
			width: 220px;	
		}
		svg{
			width: 100%;
			path,
			rect{
                stroke:$normal;
                fill:$normal;
                fill-opacity: 0;
                stroke-width:1.5;
                stroke-dasharray: 3000;
                stroke-dashoffset:3000;
                animation: lineanime 4000ms linear 800ms forwards;
            }
            @keyframes lineanime{
                0%{stroke-dashoffset:3000;fill-opacity: 0;}
                30% {fill-opacity: 0;}
                35% {fill-opacity: 1;}
                100%{stroke-dashoffset:0;fill-opacity: 1;}
			}
		}
		.catch{
			font-family: Arial, Helvetica, "sans-serif";
			color: darken($normal, 20%);
			text-align: center;
			text-transform: uppercase;
			letter-spacing: .32em;
			font-size: 9px;
			margin: 8px 0 0;
			opacity: 0;
			animation: fadeins 1200ms 800ms forwards;
			@media screen and (max-width: $phone){
				font-size: 8px;
				margin: 0;
			}
			@keyframes fadeins{
				0%{
					opacity: 0;
				}
				100%{
					opacity: 1;
				}
			}
		}
	}
}

._move{
	$time: 2800ms;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
	pointer-events: none;
    z-index: 9990;
	.left,
	.right{
		position: absolute;
		top: 0;
		left: 0;
		width: calc(50%);
		height: 100%;
	}
	.right{
		left: 50%;
	}
	.left:before,
	.left:after,
	.right:before,
	.right:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 0;
		background: #FFF;
	}
	.left:after,
	.right:after{
		top: auto;
		bottom: 0;
		left: 50%;
	}
	.load{
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 64px;
		z-index: 3;
		@media screen and (max-width: $phone){
			width: 48px;	
		}
		img{
			width: 100%;
			display: block;
		}
	}
	&.moved{
		width: 100%;
		height: 100%;
		pointer-events: auto;
		.left,
		.right{
			&:before,
			&:after{
				animation: mo $time forwards;
			}
		}
		.load{
			animation: moimg $time forwards;
		}
	}
    @keyframes mo{
        0%{
            height: 0;
        }
        25%{
            height: 100%;
        }
        75%{
            height: 100%;
			opacity: 1;
        }
        100%{
            height: 100%;
			opacity: 0;
        }
    }
    @keyframes moimg{
        0%{
            opacity: 0;
        }
        10%{
            opacity: 0;
        }
        30%{
			opacity: 1;
        }
        80%{
			opacity: 0;
        }
        100%{
			opacity: 0;
        }
        100%{
			opacity: 0;
        }
    }
}


#pagetop.pagetop{
	$size: 64px;
	position: fixed;
	right: 32px;
	bottom: 32px;
	border-radius: 50%;
	overflow: hidden;
	background: #FFF;
	z-index: 2000;
	width: $size;
	height: $size;
	@include flex();
	align-items: center;
	justify-content: center;
	text-align: center;
	color: $normal;
	line-height: 1;
	transform: translateY(100%) rotate(45deg);
	bottom: 0;
	@include transition(400ms, $ease: $ease);
	@media screen and (max-width: $tab){
		width: 48px;
		height: 48px;
		right: 15px;
	}
	&.active{
		bottom: 32px;
		transform: translateY(0) rotate(0deg);
		box-shadow: 0 0 40px rgba(#000,.16);
		@media screen and (max-width: $tab){
			bottom: 15px;	
		}
	}
	&:hover{
		i{
			transform: rotate(180deg);
		}
	}
	i{
		display: block;
		font-size: 24px;
		margin: 0 0 6px;
		transition: transform 400ms $ease, opacity 400ms linear;
		@media screen and (max-width: $tab){
			font-size: 18px;
			margin: 0 0 4px;
		}
	}
	small{
		display: block;
		font-size: 10px;
		@media screen and (max-width: $tab){
			font-size: 9px;	
		}
	}
}

.scrollanime{
    $speed: 600ms;
    $delay: 80ms;
    &__view{
        opacity: 0;
        transform: translateY(40px); 
    }
    &._active{
        .scrollanime__view{
            @for $i from 1 through 100{
                &:nth-of-type(#{$i}){
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity $speed $delay*$i ease, transform $speed $delay*$i ease; 
                }
            }
        }
    }
}

.scroll-img{
	position: relative;
	$speed: 500ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: $glay;
		@include transition($speed , $delay:$speed , $ease:$ease);
		z-index: 2;
	}
	&:after{
		background: darken($glay,8%);
		@include transition($speed , $ease: $ease);
		z-index: 3;
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scrollfill{
    &__view{
        display: inline-block;
        overflow: hidden;
        position: relative;
        vertical-align: bottom;
        &:before,
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:before{
            left: auto;
            right: 0;
            background: $normal;
            z-index: 2;
        }
        &:after{
            width: 0;
            background: $glay;
            z-index: 3;
        }
        &--glay{
            &:before{
                background: $glay;
            }
        }
    }
    &._active{
        .scrollfill__view{
            @for $i from 1 through 10{
                &:nth-of-type(#{$i}){
                    &:before{
                        width: 0;
                        @include transition(300ms, 300ms*$i, $ease);
                    }
                    &:after{
                        animation: scrollfill 600ms 300ms*$i $ease forwards;
                        @keyframes scrollfill{
                            0%{
                                width: 0;
                                left: 0;
                            }
                            50%{
                                width: 100%;
                                left: 0;
                            }
                            100%{
                                width: 0%;
                                left: 100%;
                            }
                        }
                    }       
                }
            }
        }
    }
}

.wpcf7-not-valid-tip{
	text-align: left;
	display: block;
	margin: 8px 0 0;
}
.wpcf7-response-output{
	border-color: $normal !important;
	padding: 10px 15px !important;
	font-weight: 700;
}

.grecaptcha-badge{
	z-index: -1;
	&.active{
		z-index: 2;
		bottom: 124px !important;
		@media screen and (max-width: $phone){
			bottom: 80px !important;
		}
	}
}
