.site-service{
	.bggl{
		background: darken($glay, 3%);
	}
	.context{
		dt{
			font-weight: 700;
			font-size: 36px;
			line-height: 1;
			text-align: center;
			padding: 0 0 56px;
			position: relative;
			@media screen and (max-width: $phone){
				font-size: 24px;
				padding: 0 0 40px;
			}
			&:before{
				content: '';
				width: 48px;
				height: 2px;
				background: $black;
				position: absolute;
				bottom: 24px;
				left: 50%;
				border-radius: 3px;
				transform: translateX(-50%) translateY(50%);
				@media screen and (max-width: $phone){
					bottom: 18px;
				}
			}
		}
		dd{
			width: 640px;
			margin: 0 auto;
			font-size: 16px;
			text-align: justify;
			@media screen and (max-width: $phone){
				width: auto;
				font-size: 13px;
				margin: 0 15px;
			}
		}
	}
	.yattemasu{
		border: $black 3px solid;
		background: #FFF;
		color: $normal;
		padding: 10px 0 12px;
		text-align: center;
		font-size: 24px;
		border-radius: 999px;
		letter-spacing: .08em;
		position: absolute;
		width: 500px;
		left: 50%;
		bottom: -116px;
		transform: translateX(-50%);
		z-index: 3;
		box-shadow: 0 0 24px rgba($black,.08);
		@media screen and (max-width: $tab){
			font-size: 18px;
			bottom: -96px;
		}
		@media screen and (max-width: $phone){
			width: 280px;
			padding: 8px 0 6px;
			font-size: 18px;
			bottom: -64px;
		}
		strong{
			position: relative;
			z-index: 3;
		}
		&:before{
			content: '';
			width: 32px;
			height: 32px;
			border: $black 3px solid;
			background: #FFF;
			position: absolute;
			bottom: -3px;
			left: 50%;
			transform: translateX(-50%) translateY(50%) rotate(45deg);
			z-index: -1;
			@media screen and (max-width: $phone){
				width: 24px;
				height: 24px;
				bottom: -2px;
			}
		}
		&:after{
			content: '';
			width: 32px;
			height: 32px;
			background: #fff;
			position: absolute;
			left: 50%;
			bottom: -15px;
			transform: translateX(-50%) rotate(45deg);
			@media screen and (max-width: $phone){
				width: 24px;
				height: 24px;
				bottom: -10px;
			}
		}
	}
	
	.servicebox{
		margin: 0 0 80px;
		padding: 0 4% 80px;
		border-bottom: #FFF 4px solid;
		@media screen and (max-width: $tab){
			margin: 0 0 30px;
			padding: 0 30px 30px;
		}
		@media screen and (max-width: $phone){
			margin: 0 0 40px;
			padding: 0 15px;
			border: none;
		}
		&:last-of-type{
			margin-bottom: 0;
			padding-bottom: 0;
			border: none;
		}
		&:first-of-type{
			margin-top: 40px;
		}
		h2{
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		&__list{
			@include flex();
			justify-content: center;
			margin: 0 0 0 -20px;
			@media screen and (max-width: $phone){
				margin: 0 0 0 -15px;	
			}
			> li{
				width: 25%;
				padding: 0 0 0 20px;
                @include flex();
                align-items: center;
                justify-content: center;
				@media screen and (max-width: $phone){
					width: 50%;
					padding: 0 0 0 15px;
					margin: 15px 0 0;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				a{
					display: block;
					color: $normal;
                    width: 220px;
                    height: 220px;
                    border: #FFF 0px solid;
					background: #FFF;
                    border-radius: 50%;
                    overflow: hidden;
                    @include flex();
                    align-items: center;
                    justify-content: center;
					position: relative;
					transition: border 200ms $ease, transform 200ms $ease;
					@media screen and (max-width: $tab){
						width: 150px;
						height: 150px;
					}
					@media screen and (max-width: $phone){
						width: 140px;
						height: 140px;
					}
					figure{
						text-align: center;
						> *{
							z-index: 2;
						}
						img{
							width: 36%;
							opacity: .96;
							@media screen and (max-width: $phone){
								width: 32%;	
							}
						}
						figcaption{
							width: 100%;
							margin: 16px 0 0;
							font-weight: 700;
							font-size: 17px;
							@media screen and (max-width: $tab){
								font-size: 15px;	
							}
							@media screen and (max-width: $phone){
								font-size: 13px;
								margin: 10px 0 0;
							}
						}
					}
					&:hover{
						border-width: 5px;
						border-color: $black;
					}
				}
			}
		}
	}
	
	&.detail{
		.glay{
			background: darken($glay, 2%);
		}
		.bot{
			border-top: #FFF 8px solid;
		}
		.catch{
			text-align: center;
			dt{
				font-size: 26px;
				font-weight: 700;
				line-height: 1.6;
				margin: 0 0 10px;
				letter-spacing: .024em;
				@media screen and (max-width: $phone){
					font-size: 18px;
					text-align: center;
				}
				&:before{
					content: attr(data-eng);
					font-size: 12px;
					display: block;
					margin: 0 0 2px;
				}
			}
			dd{
				font-size: 16px;
				transform: translateY(12px);
				@media screen and (max-width: $phone){
					font-size: 13px;
					margin: 0 15px;
				}
				&:before{
					content: '';
					width: 40px;
					height: 2px;
					background: $black;
					margin: 12px auto 20px;
					display: block;
					@media screen and (max-width: $phone){
						margin: 8px auto 16px;
					}
				}
			}
		}
		.clumswrapper{
			@media screen and (max-width: $tab){
				&.wrap.mid{
					padding-top: 0;	
				}	
			}
		}
		.clums{
			position: relative;
			@include flex();
			align-items: center;
			justify-content: flex-end;
			@media screen and (max-width: $tab){
				display: block;	
			}
			&__bg{
				position: absolute;
				top: 0;
				left: 0;
				width: 56%;
				height: 100%;
				background-size: cover;
				@media screen and (max-width: $tab){
					position: relative;
					width: 100%;
					height: 300px;
				}
				@media screen and (max-width: $phone){
					height: 220px;	
				}
			}
			&__txt{
				width: 44%;
				padding: 60px 56px;
				@media screen and (max-width: $tab){
					width: auto;
					padding: 60px 30px;
					padding-bottom: 0;
					text-align: center;
				}
				@media screen and (max-width: $phone){
					padding-top: 32px;
				}
				&__box{
					max-width: 560px;
					margin: 0 auto;
					@media screen and (max-width: $tab){
						max-width: 100%;	
					}
					dt{
						font-size: 26px;
						font-weight: 700;
						line-height: 1.6;
						letter-spacing: .024em;
						margin: 0 0 16px;
						@media screen and (max-width: $phone){
							font-size: 18px;
							margin: 0 15px 12px;
						}
					}
					dd{
						font-size: 15px;
						text-align: justify;
						@media screen and (max-width: $tab){
							width: 80%;
							text-align: justify;
							margin: 0 auto;
							&.btnarea{
								text-align: center;
								margin-top: 30px;
							}
						}
						@media screen and (max-width: $phone){
							width: auto;
							margin: 0;
							font-size: 13px;	
						}
					}
				}
			}
		}
		.boxer{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -32px;
			@media screen and (max-width: $phone){
				margin: 0;	
			}
			&__item{
				@media screen and (min-width: $tab + 1px){
					width: 33.3%;
					padding: 0 0 0 32px;
					@include flex();
					margin: 32px 0 0;
					&:nth-child(-n+3){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $tab){
					width: 50%;
					padding: 0 0 0 32px;
					@include flex();
					margin: 32px 0 0;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				&__box{
					background: #FFF;
					height: auto;
				}
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					padding: 24px;
					@media screen and (max-width: $phone){
						padding: 15px;	
					}
					dt{
						font-size: 17px;
						font-weight: 700;
						text-align: center;
						padding: 0 0 10px;
						margin: 0 0 12px;
						border-bottom: $border 1px solid;
					}
					dd{
						text-align: justify;
					}
				}
			}
		}
		.flow{
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(20% - 2px);
				margin: 2px 2px 0 0;
				background: $glay;
				padding: 20px;
				padding-top: 0;
				position: relative;
				@media screen and (max-width: $tab){
					width: calc(33% - 2px);	
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 15px;
					margin: 0 0 15px;
					@include flex();
					align-items: center;
					&:last-child{
						margin: 0;
					}
				}
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						&:before{
							content: '#{$i}';
							position: absolute;
							top: 0;
							left: 0;
							width: 40px;
							height: 40px;
							background: $black;
							color: #FFF;
							@include flex();
							align-items: center;
							justify-content: center;
							@media screen and (max-width: $phone){
								left: auto;
								right: 0;
								width: 24px;
								height: 24px;
							}
						}
					}
				}
				i{
					font-size: 32px;
					display: block;
					text-align: center;
					padding: 40px 0 16px;
					@media screen and (max-width: $phone){
						width: 48px;
						height: 48px;
                        padding: 0;
						@include flex();
						align-items: center;
						justify-content: center;
					}
				}
				dl{
					@media screen and (max-width: $phone){
						width: calc(100% - 48px);
						padding: 0 0 0 15px;
					}
					dt{
						font-size: 16px;
						font-weight: 700;
						text-align: center;
						@media screen and (max-width: $phone){
							text-align: left;	
						}
					}
					dd{
						margin: 12px 0 0;
						@media screen and (max-width: $phone){
							margin: 0;	
						}
						&.btnarea{
							@media screen and (max-width: $phone){
								margin: 12px 0 0;	
							}
							a{
								display: block;
								padding: 8px 15px;
								font-size: 14px;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}