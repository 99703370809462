#site-footer{
	background: $black;
	color: #FFF;
	a{
		color: #FFF;
	}
	.flex{
		@include flex();
		padding-top: 60px;
		@media screen and (max-width: $tab){
			display: block;	
		}
	}
	.companydata{
		width: 500px;
		@media screen and (max-width: $tab){
			width: auto;
			@include flex();
		}
		@media screen and (max-width: $phone){
			display: block;	
		}
		h5{
			margin: 0 0 4px;
			font-size: 13px;
			@media screen and (max-width: $tab){
				text-align: center;
				width: 100%;
				&+p{
					text-align: center;
					width: 100%;
				}
			}
			@media screen and (max-width: $phone){
				&+p{
					font-size: 11px;
				}
			}
			img{
				width: 160px;
			}
		}
		&__addr{
			font-size: 12px;
			margin: 12px 0 0;
			@media screen and (max-width: $tab){
				width: calc(50% - 20px);	
			}
			@media screen and (max-width: $phone){
				width: auto;
				margin: 12px 15px 0;
			}
			strong{
				font-weight: 700;
				font-size: 17px;
			}
		}
		&__logo{
			margin: 24px 0 0;
			width: 100%;
			@media screen and (max-width: $tab){
				margin: 12px 0 0;
			}
			@media screen and (max-width: $phone){
				margin: 8px 15px 0;
			}
			a{
				width: 114px;
				display: block;
			}
			img{
				width: 100%;
			}
		}
	}
	.footnav{
		width: calc(100% - 500px);
		@include flex();
		@media screen and (max-width: $tab){
			width: auto;
			margin: 24px 0 0;
			padding: 24px 0 0;
			border-top: rgba(#FFF,.12) 1px solid;
			ul{
				width: 33.3%;
			}
		}
		@media screen and (max-width: $phone){
			display: block;
			ul{
				width: auto;
				margin: 0 0 1px;
				overflow: hidden;
				&:first-child{
					border-radius: 5px 5px 0 0;
				}
				&:last-child{
					border-radius: 0 0 5px 5px;
				}
			}
		}
		li{
			margin: 0 0 8px;
			@media screen and (max-width: $phone){
				margin: 0 0 1px;	
			}
			&:last-child{
				margin: 0;
			}
			a{
				font-size: 10px;
				&:hover{
					opacity: .64;
				}
				@media screen and (max-width: $phone){
					font-size: 10px;
					display: block;
					padding: 12px 15px;
					background: rgba(#FFF,.08);
					position: relative;
					color: rgba(#FFF,.88);
					&:after{
						content: "\f054";
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-50%);
						font-size: 10px;
						opacity: .24;
					}
				}
				&:before{
					content: attr(data-eng);
					font-size: 16px;
					display: inline-block;
					margin: 0 16px 0 0;
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
				}
			}
		}
	}
	.sns{
		position: absolute;
		top: 56px;
		right: 40px;
		@include flex();
		@media screen and (max-width: $phone){
			position: relative;
			top: 0;
			right: auto;
			justify-content: center;
			margin: 32px 0 0;
		}
		li{
			margin: 0 28px 0 0;
			&:last-child{
				margin: 0;
			}
			a{
				opacity: .24;
				i{
					font-size: 20px;
				}
				&:hover{
					opacity: 1;
				}
			}
		}
	}
	.copy{
		display: block;
		padding: 60px 0;
		font-size: 9px;
		text-transform: uppercase;
		text-indent: -.2em;
		@media screen and (max-width: $tab){
			padding-bottom: 80px;	
		}
		@media screen and (max-width: $phone){
			padding-top: 24px;
			text-align: center;
		}
	}
}