.site-blog{
	.flex{
		@include flex();
		@media screen and (max-width: $tab){
			display: block;	
		}
	}
	.container{
		width: calc(100% - 360px);
		@media screen and (max-width: $tab){
			width: auto;	
		}
	}
	.sidebar{
		width: 360px;
		padding: 0 0 0 64px;
		@media screen and (max-width: $tab){
			width: auto;
			padding: 32px 0 0;
		}
		&__box{
			margin: 0 0 56px;
			@media screen and (max-width: $tab){
				margin: 0 0 32px;	
			}
			&:last-of-type{
				margin: 0;
			}
		}
	}
	.bloglist{
		> li{
			@include flex();
			margin: 0 0 32px;
			padding: 0 0 32px;
			border-bottom: $border 1px solid;
			a{
				@include flex();
				width: 100%;
				color: $normal;
				position: relative;
			}
			figure{
				width: 220px;
				height: 140px;
				@media screen and (max-width: $phone){
					width: 120px;
					height: 80px;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			.heart{
				position: absolute;
				top: 0;
				right: 0;
                font-size: 13px;
                z-index: 2;
                i{
                    color: $red;
                    display: inline-block;
                    margin: 0 6px 0 0;
                }
			}

			dl{
				width: calc(100% - 220px);
				padding: 0 0 0 32px;
				@media screen and (max-width: $phone){
					width: calc(100% - 120px);
					padding: 0 0 0 15px;
				}
				.meta{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					font-size: 13px;
					.category{
						@include flex();
						align-items: center;
						justify-content: flex-start;
						@media screen and (max-width: $phone){
							margin: 6px 0 0;	
						}
					}
					time,
					li{
						border: $normal 1px solid;
						padding: 2px 0;
						display: inline-block;
						margin: 0 10px 0 0;
						width: 120px;
						text-align: center;
						@media screen and (max-width: $phone){
							width: 80px;
							font-size: 10px;
						}
					}
					li{
						background: $glay;
						border-color: $glay;
						padding: 2px 16px;
						width: auto;
						@media screen and (max-width: $phone){
							font-size: 10px;	
						}
					}
				}
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 10px 0;
					@media screen and (max-width: $phone){
						font-size: 15px;
						margin: 6px 0 0;
					}
				}
				dd.desc{
					text-align: justify;
					font-size: 15px;
					line-height: 1.66;
					@media screen and (max-width: $phone){
						display: none;
					}
				}
			}
		}
        &.mini{
            > li{
				margin: 0 0 20px;
				padding: 0 0 20px;
				@media screen and (max-width: $phone){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                a{
                    figure{
                        width: 80px;
						height: 50px;
                    }
                    dl{
                        width: calc(100% - 80px);
						padding: 0 0 0 20px;
						.meta{
							font-size: 10px;
						}
						dt{
							font-size: 14px;
							margin: 4px 0 0;
						}
                    }
                }
            }
        }
	}
	.newslist{
		> li{
			margin: 0 0 32px;
			padding: 0 0 32px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 16px;
				padding: 0 0 16px;
			}
			a{
				color: $normal;
				position: relative;
				padding: 0 32px 0 0;
				display: block;
				&:hover{
					opacity: .64;
				}
				&:after{
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					font-size: 11px;
				}
			}
			dl{
				@include flex();
				@media screen and (max-width: $phone){
					align-items: flex-start;	
				}
				dd{
					border: $normal 1px solid;
					padding: 2px 0;
					display: inline-block;
					width: 120px;
					text-align: center;
					@media screen and (max-width: $phone){
						width: 80px;	
						font-size: 10px;
						padding: 3px 0;
					}
				}
				dt{
					font-weight: 700;
					font-size: 16px;
					width: calc(100% - 120px);
					padding-left: 24px;
					@media screen and (max-width: $phone){
						width: calc(100% - 80px);
						padding-left: 15px;
						font-size: 14px;
						line-height: 1.5;
					}
				}
			}
		}

	}
    .categorylist{
		transform: translateY(-15px);
        li{
			border-bottom: $border 1px solid;
            a{
                padding: 15px 4px;
                padding-right: 32px;
				display: block;
				color: $normal;
				position: relative;
				dl{
					dd{
						border: $normal 1px solid;
						padding: 1px 0;
						display: inline-block;
						width: 120px;
						text-align: center;
						margin: 0 0 4px;
					}
					dt{
						font-weight: 700;
						font-size: 14px;
					}
				}

				&:hover{
					opacity: .64;
				}
				&:after{
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					font-size: 11px;
				}
            }
        }
    }
	
	
	//SINGLE
	.single{
		&__title{
			border: $border 1px solid;
			padding: 24px;
			position: relative;
			@media screen and (max-width: $phone){
				padding: 15px;	
			}
			&:before{
				content: '';
				width: calc(100% + 1px);
				height: 5px;
				background: $black;
				position: absolute;
				top: -1px;
				left: -1px;
			}
			h2{
				font-weight: 700;
				font-size: 24px;
				line-height: 1.66;
				margin: 0 0 12px;
				@media screen and (max-width: $phone){
					font-size: 16px;
					padding: 0 40px 0 0;
				}
			}
			.heart{
				position: absolute;
				top: 24px;
				right: 24px;
                font-size: 13px;
                z-index: 2;
				@media screen and (max-width: $phone){
					top: 15px;
					right: 15px;
				}
                i{
                    color: $red;
                    display: inline-block;
                    margin: 0 6px 0 0;
                }
			}
		}
		&__meta{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			font-size: 13px;
			.category{
				@include flex();
				align-items: center;
				justify-content: flex-start;
			}
			time,
			li{
				border: $normal 1px solid;
				padding: 2px 16px;
				display: inline-block;
				margin: 0 10px 0 0;
				@media screen and (max-width: $phone){
					font-size: 10px;	
				}
			}
			li{
				background: $glay;
				border-color: $glay;
			}
		}
		&__catch{
			margin: 24px 0 0;
			img{
				width: 100%;
			}
		}
		.index{
			background: darken($glay, 1%);
			border-radius: 2px;
			padding: 24px;
			margin: 24px 0 0;
			@media screen and (max-width: $phone){
				padding: 15px;	
			}
			h3{
				font-size: 20px;
				font-weight: 700;
				line-height: 1;
				padding: 0 0 16px;
				margin: 0 0 16px;
				border-bottom: rgba($black,.08) 1px solid;
				@media screen and (max-width: $phone){
					font-size: 16px;	
				}
				i{
					display: inline-block;
					margin: 0 12px 0 0;
				}
			}
			ol{
				li{
					list-style: inside decimal;
					font-size: 16px;
					margin: 0 0 4px;
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
					&:last-child{
						margin: 0;
					}
					a{
						color: $normal;
						text-decoration: underline;
					}
				}
			}
		}
	}
}