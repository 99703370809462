.site-recruit{
	.context{
		text-align: center;
		margin: 0 0 32px;
		@media screen and (max-width: $phone){
			margin: 0 0 20px;	
		}
		dt{
			font-size: 32px;
			line-height: 1;
			font-weight: 700;
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 17px;
				margin: 0 0 15px;
			}
		}
		dd{
			font-size: 15px;
			line-height: 1.82;
			@media screen and (max-width: $phone){
				font-size: 13px;
				margin: 0 15px;
				text-align: justify;
			}
		}
	}
}