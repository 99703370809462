.site-index{
	.firstview{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $black;
			opacity: .04;
		}
		&:before{
			background: $normal;
			opacity: .04;	
		}
		&:after{
			box-shadow: inset 0 10vh 40vh rgba($normal,.64);
		}
		&__content{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			color: #FFF;
			@include flex();
			align-items: center;
			justify-content: flex-start;
			&__title{
				margin: 0 0 0 8vw;
				opacity: 0;
				&__catch{
					font-family:'DM Sans', sans-serif;
					@include fontsize(88px, 36);
					font-weight: 500;
					letter-spacing: -.008em;
					line-height: 1;
					@media screen and (max-width: $phone){
						@include fontsize(50px, 20);
					}
					span{
						display: inline-block;
						transform: translateY(20px);
						opacity: 0;
					}
				}
				&__sub{
					@include fontsize(15px, 7);
					font-weight: 700;
					margin: 20px 0 0;
					letter-spacing: .08em;
					text-indent: .24em;
                    transform: translateY(10px);
                    opacity: 0;
					@media screen and (max-width: $phone){
						@include fontsize(12px, 3);
						margin: 8px 0 0;
					}
				}
			}
			&__copy{
				position: absolute;
				left: 50px;
				bottom: 32px;
				font-size: 9px;
				letter-spacing: .24em;
				@media screen and (max-width: $phone){
					left: 15px;
					bottom: 15px;
				}
			}
			&__scroll{
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				white-space: nowrap;
				font-size: 10px;
				letter-spacing: .24em;
				position: absolute;
				bottom: 0;
				right: 50px;
				line-height: 1;
				padding: 0 15px 66px 0;
				$scroll: 120px;
				@media screen and (max-width: $phone){
					right: 40px;	
				}
				&:before,
				&:after{
					content: '';
					width: 1px;
					height: $scroll;
					background: #FFF;
					position: absolute;
					right: 0;
					bottom: 0;
				}
				&:before{
					opacity: .24;
				}
				&:after{
					animation: scg 1600ms infinite;
					@keyframes scg{
						0%{
							height: $scroll;
							bottom: 0;
						}
						33%{
							height: 0;
							bottom: 0;
						}
						65%{
							height: 0;
							bottom: $scroll;
						}
						66%{
							height: 0;
							bottom: $scroll;
						}
						100%{
							height: $scroll;
							bottom: 0;
						}
					}
				}
			}
			&__sns{
				position: absolute;
				top: 50%;
				right: 50px;
				transform: translateY(-50%) translateX(-50%);
				text-align: center;
				@media screen and (max-width: $phone){
					right: 15px;
				}
				li{
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				a{
					display: block;
					transition: transform 200ms $ease;
					&:hover{
						transform: scale(2);
					}
				}
				i{
					color: #FFF;
					font-size: 13px;
				}
			}
		}
		&__fade{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
			background: $black;
			opacity: 0;
			pointer-events: none;
		}
		.dammy,
		#video{
			position: absolute;
			top: 0;
			left: 0;
			-o-object-fit: cover;
			object-fit: cover;
			object-position: center center;
			width: 100vw;
			height: 100vh;
			transform: scale(1);
			z-index: -1;
			background: #000;
		}
		.dammy{
			background: url(../../../images/demo/fv.jpg) no-repeat center;
			background-size: cover;
			transform: scale(1.2);
		}
		&.active{
			.firstview__content__title{
				opacity: 1;
				.firstview__content__title__catch{
					span{
						opacity: 1;
						transform: translateY(0);
						@for $i from 1 through 50{
							&:nth-of-type(#{$i}){
								transition: opacity 400ms $i*60ms,
											transform 400ms $i*60ms;
							}
						}
					}
				}
				.firstview__content__title__sub{
					opacity: 1;
					transform: translateY(0);
                    transition: opacity 800ms 1600ms,
                                transform 800ms 1600ms;
				}
			}
			.dammy{
				opacity: 1;
				transform: scale(1);
				transition: transform 8000ms linear;
			}
		}
	}
}