.titlearea{
	position: relative;
	height: 440px;
	@include flex();
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	@media screen and (max-width: $tab){
		height: 320px;	
	}
	@media screen and (max-width: $phone){
		height: 240px;	
	}
	&.active{
		.titlearea__bg{
			transform: scale(1);
			transition: transform 2000ms linear;
		}
		.titlearea__ttl{
			.eng,
			h1{
				opacity: 1;
				span{
					transform: translateY(0);
					opacity: 1;
					@for $i from 1 through 20{
						&:nth-of-type(#{$i}){
							transition: transform 200ms $i*40ms, opacity 200ms $i*40ms;
						}
					}
				}
			}
		}
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $normal;
		opacity: .04;
		z-index: 0;
	}
	&__bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		transform: scale(1.06);
		z-index: -1;
	}
	&__ttl{
		color: #FFF;
		position: relative;
		z-index: 2;
		margin: 0 0 0 8vw;
		font-size: 20px;
		transform: translateY(10%);
		@media screen and (max-width: $tab){
			font-size: 17px;	
		}
		@media screen and (max-width: $phone){
			font-size: 13px;	
		}
        .eng{
            content: attr(data-eng);
            font-size: 56px;
            display: block;
            line-height: 1;
            letter-spacing: .03em;
            margin: 0 0 18px;
			@media screen and (max-width: $tab){
				font-size: 40px;	
			}
			@media screen and (max-width: $phone){
				font-size: 28px;
				margin: 0 0 8px;
			}
        }
		h1{
			line-height: 1;
			letter-spacing: .12em;
		}
		.eng,
		h1{
			font-weight: 700;
			opacity: 0;
			span{
				display: inline-block;
				opacity: 0;
				transform: translateY(20px);
			}
		}
	}
	&__bread{
		position: absolute;
		left: 8vw;
		bottom: 24px;
		@media screen and (max-width: $phone){
			left: 15px;
			bottom: 15px;
		}
		ol{
			@include flex();
			font-size: 12px;
			color: #FFF;
			@media screen and (max-width: $phone){
				font-size: 10px;	
			}
			li{
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
				&:after{
					content: "\f054";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					display: inline-block;
					margin: 0 16px;
					font-size: 8px;
					transform: translateY(-1.5px);
					@media screen and (max-width: $phone){
						margin: 0 8px;	
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
				a{
					color: #FFF;
				}
			}
		}
	}
}