.site-company{
	.figlist{
		@include flex();
		align-items: center;
		justify-content: flex-start;
		li{
			width: 22.5%;
			margin: 3.3% 3.3% 0 0;
			border: $border 1px solid;
			@media screen and (min-width: $phone + 1px){
				&:nth-child(-n+4){
					margin-top: 0;
				}
				&:nth-child(4n+4){
					margin-right: 0;
				}
			}
			@media screen and (max-width: $phone){
				width: 31%;
				margin: 3.5% 3.5% 0 0;
				&:nth-child(-n+3){
					margin-top: 0;
				}
				&:nth-child(3n+3){
					margin-right: 0;
				}
			}
			img{
				width: 100%;
			}
		}
	}
	.map{
		position: relative;
		@include flex();
		border-top: $glay 8px solid;
		@media screen and (max-width: $phone){
			display: block;	
		}
		&:before,
		&:after{
			content: '';
			width: 1px;
			height: 100%;
			background: $border;
			position: absolute;
			top: 0;
			left: 33.3%;
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&:after{
			left: auto;
			right: 33.3%;
		}
		&__item{
			width: 33.3%;
			padding: 20px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 15px;
			}
			figure{
				padding: 12px 20px 32px;
				text-align: center;
				@media screen and (max-width: $phone){
					padding: 15px;
					padding-bottom: 20px;
				}
				img{
					width: 200px;
					&.min{
						width: 86px;
						@media screen and (max-width: $phone){
							width: 160px;	
						}
					}
					@media screen and (max-width: $phone){
						width: 180px;	
					}
				}
				figcaption{
					font-weight: 700;
					margin: 4px 0 0;
				}
			}
		}
		iframe{
			width: 100%;
			height: 340px;
			filter: grayscale(1);
			vertical-align: bottom;
			@media screen and (max-width: $phone){
				height: 200px;	
			}
		}
	}
	.widebtn{
		padding: 48px 0;
		background: $glay;
		@media screen and (max-width: $phone){
			padding: 24px 0;	
		}
		.underbtn{
			margin-top: 0;
		}
	}
	.contact{
		position: relative;
		background: $black;
		padding: 40px 8vw;
		@include flex();
		justify-content: flex-end;
		@media screen and (max-width: $phone){
			padding: 32px 15px;	
		}
		&:before{
			content: '';
			width: 100%;
			height: 1px;
			background: lighten($black,16%);
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 2;
		}
		a{
			color: #FFF;
			display: inline-block;
			position: relative;
			padding: 0 72px 0 0;
			@include lineAnime(40px,20px,#FFF);
			&:hover{
				opacity: .24;
				color: #FFF !important;
				&:before,
				&:after{
					background: #FFF !important;
				}
			}
		}
	}
	.studios{
		@include flex();
		justify-content: flex-start;
		margin-top: 20px;
		@media screen and (max-width: $phone){
			display: block;
		}
		figure{
			width: 160px;
			margin: 0 0 0 32px;
			@media screen and (max-width: $phone){
				margin: 16px 0 0;	
			}
			img{
				width: 100%;
			}
		}
	}
}